import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-gainesville-florida.png'
import image0 from "../../images/cities/scale-model-of-america's-escape-game-gainesville-in-gainesville-florida.png"
import image1 from "../../images/cities/scale-model-of-visit-gainesville,-alachua-county,-fl-in-gainesville-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Gainesville'
            state='Florida'
            image={image}
            lat='29.6516344'
            lon='-82.32482619999996'
            attractions={ [{"name": "America's Escape Game Gainesville", "vicinity": "239 W University Ave, Gainesville", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 29.6517871, "lng": -82.32751780000001}, {"name": "Visit Gainesville, Alachua County, FL", "vicinity": "33 N Main St, Gainesville", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 29.6524262, "lng": -82.32487960000003}] }
            attractionImages={ {"America's Escape Game Gainesville":image0,"Visit Gainesville, Alachua County, FL":image1,} }
       />);
  }
}